import { richText, stripRichTextWrapperTag } from '@/utils/html';
import callbackAdapter from '~/utils/freespee/webcallback-adapter';

export default ({ primary }, pageData, { $i18n, $store }) => {
  const callbackData = primary.web_callback.data;
  callbackAdapter(callbackData, $i18n, $store);

  const introSupertitle = richText(primary.standalone_intro_supertitle);
  const introTitle = stripRichTextWrapperTag(primary.standalone_intro_title);
  const introDescription = richText(primary.standalone_intro_description);
  const isClientTitle = richText(primary.titre_deja_client);
  const isClientContent = richText(primary.message_deja_client);
  const hasIntro = introSupertitle || introTitle || introDescription;

  return {
    freespeeParams: pageData?.market?.data?.freespee_parameter,
    isLite: primary.web_callback_lite_version,
    edito: {
      title: stripRichTextWrapperTag(primary.standalone_title),
      description: richText(primary.standalone_content),
      isClient: {
        title: isClientTitle,
        content: isClientContent,
      },
      intro: hasIntro
        ? {
            supertitle: introSupertitle,
            title: introTitle,
            description: introDescription,
          }
        : null,
    },
  };
};
